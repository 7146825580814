import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import ClosedMPCustomerUnattached from "../components/FormBuilder/ClosedMPCustomerUnattached/ClosedMPCustomerUnattached";
import "../styles/form.scss";

const Closed = () => (
  <AuthenticationLayout>
    <ClosedMPCustomerUnattached />
  </AuthenticationLayout>
);

export default Closed;
