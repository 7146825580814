export const selectAllContacts = (state: any) => state.communications.contacts;
export const selectSuccessfullySentMessage = (state: any) =>
  state.communications.successfullySentMessage;
export const selectUnsuccessfulMessageSend = (state: any) =>
  state.communications.unsuccessfulMessageSend;
export const selectCustomer = (state: any) => state.communications.customer;
export const selectAgent = (state: any) => state.communications.agent;
export const selectLoanOfficer = (state: any) =>
  state.communications.loanOfficer;
export const selectIsLoading = (state: any) => state.communications.loading;

export const selectDealId = (state: any) => state.communications.selectedDealId;
export const successfullyFoundAgent = (state: any) =>
  state.communications.successfullyGotAgent;
export const successfullyFoundCustomer = (state: any) =>
  state.communications.successfullyGotCustomer;
export const successfullyFoundLoanOfficer = (state: any) =>
  state.communications.successfullyGotLoanOfficer;
export const selectErrorMessages = (state: any) => state.communications.errors;
export const selectSendCount = (state: any) => state.communications.sendCount;
// successfullyGotAgent: false,
//   successfullyGotCustomer: false,
//   successfullyGotLoanOfficer

export const getAllAdditionalContacts = (state: any) =>
  state.communications.additionalContacts;

export const selectCustomerHubspotInfo = (state: any) =>
  state.communications.customerHubspotInfo;
