import Agent from "../../../Interfaces/agent";
import { Deal } from "../../../Interfaces/deal";
import { FieldTypes } from "../../../utils/fieldTypes";

const ClosedMPDefaultFields = (agent: Agent, deal: Deal) => {
  const fields = [
    {
      label: `This survey is broken into three sections asking about your experience with your agent, ${
        deal.loan_officer.company_name || "your Mortgage Partner"
      }, and your overall experience purchasing through Clever. <br/><br/>For all scale questions, please consider 1 the lowest rating`,
      keyName: "intro",
      type: FieldTypes.paragraph,
    },
    // AGENT
    {
      label: `${agent.firstname} ${agent.lastname} of ${agent.brokerage_name}`,
      keyName: "agent_brokerage",
      type: FieldTypes.header,
    },
    {
      label: `On a scale of 1-5, please rate ${agent.firstname} on each of the following items:`,
      keyName: "scale_1_5",
      type: FieldTypes.paragraph,
    },
    {
      label: `Communication, Responsiveness, and Availability`,
      keyName: `agent_rating__communication__responsiveness__and_availability`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Knowledge of Local Area`,
      keyName: `agent_rating__knowledge_of_local_area`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Negotiations/Paperwork`,
      keyName: `agent_rating__negotiations_paperwork`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Overall Rating`,
      keyName: `agent_rating__overall_rating`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `What went well with your agent? What went poorly with your agent?`,
      keyName: `what_went_well_with_your_agent__what_went_poorly_with_your_agent___mp_`,
      type: FieldTypes.textarea,
    },
    // END AGENT
    // LOAN OFFICER
    {
      label: `${deal.loan_officer.firstname} ${deal.loan_officer.lastname} of ${
        deal.loan_officer.company_name || "your Mortgage Partner"
      }`,
      keyName: "loan_officer_header",
      type: FieldTypes.header,
    },
    {
      label: `Which lender did you choose instead of ${
        deal.loan_officer.company_name || "your Mortgage Partner"
      }?`,
      keyName: `which_lender_did_you_choose_instead_of_lender_`,
      type: FieldTypes.textfield,
      required: true,
    },
    {
      label: `Why did you pick that lender?`,
      keyName: `why_did_you_pick_that_lender_`,
      type: FieldTypes.textarea,
      required: true,
    },
    {
      label: `What could ${
        deal.loan_officer.company_name || "your Mortgage Partner"
      } have done to win your business?`,
      keyName: `what_could_lender_have_done_to_win_your_business_`,
      type: FieldTypes.textarea,
      required: true,
    },
    // END LOAN OFFICER
    // CLEVER REAL ESTATE
    {
      label: `Clever Real Estate`,
      type: FieldTypes.header,
      keyName: "clever",
    },
    {
      label: `How likely are you to refer a friend to Clever?`,
      keyName: `how_likely_are_you_to_refer_a_friend_to_clever___mp_closed_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      required: true,
    },
    {
      label: `Where did we fall short of your expectations?`,
      keyName: `where_did_we_fall_short_of_your_expectations___mp_`,
      type: FieldTypes.textarea,
      triggers: {
        how_likely_are_you_to_refer_a_friend_to_clever___mp_closed_: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
        ],
      },
    },
  ];

  return fields;
};

const getFields = (deal: Deal, agent: Agent) => {
  const fields = ClosedMPDefaultFields(agent, deal);
  return fields;
};

export { getFields };
